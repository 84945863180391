import React from 'react';
import { Outlet } from 'umi';
import AntdProvider from '@/provider/AntdProvider';
import EnvHeader from '@/components/EnvHeader';

/**
 * 全局布局文件
 */
const Layout: React.FC = () => {
  return (
    <AntdProvider>
      <EnvHeader />
      <Outlet />
    </AntdProvider>
  );
};

export default Layout;
