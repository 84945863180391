import { addDays, addMonths, format, getDaysInMonth, startOfMonth, startOfYear } from 'date-fns';
import numeral from 'numeral';
import { serialize } from 'object-to-formdata';
import Cookies from 'js-cookie';
import { SALE_SERVICE_TAGS } from '@/utils/constants';

numeral.register('locale', 'chs', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: '十亿',
    trillion: '兆',
  },
  ordinal() {
    return '.';
  },
  currency: {
    symbol: '¥',
  },
});
numeral.locale('chs');

export const formatTime = (t: string | number | Date, f = 'yyyy-MM-dd HH:mm:ss') =>
  format(t ? new Date(t) : new Date(), f);

export const zhDate = (t: string | number | Date) => {
  const date = t ? new Date(t) : new Date();
  return new Intl.DateTimeFormat('zh', { dateStyle: 'long' }).format(date);
};

export const yuan = (val: any) => (val != null ? numeral(val).format('$0,0.00') : '');

export const percent = (val: any) => (val != null ? numeral(val).format('0.00%') : '');

export const getBase64 = (img: Blob, callback = (v?: any) => v) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      callback(reader.result);
      resolve(reader.result);
    });
    reader.readAsDataURL(img);
  });

export const getImageInfo = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = () => reject(false);
  });

export const objectToFormdata = (
  obj: any,
  options: any = {},
  existingFormData?: FormData,
  keyPrefix?: string,
) => serialize(obj, { indices: true, ...options }, existingFormData, keyPrefix);

export const booleanText = (v: boolean) => (v === false ? '否' : '是');

export const getDaytoArray = (time: number | Date) => {
  const days = getDaysInMonth(time);
  const start = startOfMonth(time);
  const arr = [];
  for (let i = days; i > 0; i--) {
    arr.unshift(formatTime(addDays(start, i - 1), 'yyyy-MM-dd'));
  }
  return arr;
};

export const getMonthtoArray = (time: number | Date) => {
  const months = 12;
  const start = startOfYear(time);
  const arr = [];
  for (let i = months; i > 0; i--) {
    arr.unshift(formatTime(addMonths(start, i - 1), 'yyyy-MM'));
  }
  return arr;
};

export const PHONE_REG =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

export const isBrowser = typeof window !== 'undefined';

export const clearAllCookie = () =>
  Object.keys(Cookies.get() || {}).forEach((v) => Cookies.remove(v));

export const getSaleServiceTags = (lineItem: Record<string, any>) => {
  const tags = [];
  if (!!lineItem.sale_service) {
    const type = lineItem.sale_service_type;
    const tag = SALE_SERVICE_TAGS[type];
    if (tag) tags.push({ tag: tag, type: type });
  }
  return tags;
};

// 包含赠品的line_items
export const getPureLineItems = (lineItems: Record<string, any>[]) => {
  if (!(Array.isArray(lineItems) && lineItems.length > 0)) return [];

  const giftLineItems = lineItems.filter((lineItem) => !!lineItem.is_gift);
  const vaildLineItems = lineItems.filter((lineItem) => !lineItem.is_gift);

  vaildLineItems.forEach((lineItem) => {
    const children = lineItem.children || [];
    giftLineItems.forEach((giftLineItem) => {
      if (lineItem.id === giftLineItem.from_item_id) children.push(giftLineItem);
    });
    lineItem.children = children;
  });

  return vaildLineItems;
};
