import React from 'react';
import { STAGING_HOSTNAME } from '@/utils/constants';

const EnvHeader: React.FC = () => {
  const url = new URL(window.location.href);
  if (url.hostname !== STAGING_HOSTNAME) return null;

  return (
    <div className="flex items-center bg-[#f0f0f0] px-8 py-2">
      <div className="text-blod mr-6 text-4xl font-bold italic invert-[.4]">DEMO</div>
      <div className="invert-[.4]">
        / 测试版 / 可随意操作，不会影响正式环境，但数据不会永久保存 /
      </div>
    </div>
  );
};
export default EnvHeader;
