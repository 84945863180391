import useMedia from '@/hooks/useMedia';

/**
 * 全局响应式布局信息
 * 用户系统布局变化
 */
export default () => {
  const isMd = useMedia('(max-width: 768px)');

  return { isMd };
};
