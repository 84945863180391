import { useState } from 'react';
import api from '@/service/api';
import { arrayMoveImmutable } from 'array-move';
import { objectToFormdata } from '@/utils';

interface SortEndProps {
  oldIndex: number;
  newIndex: number;
  collection: string | number;
}

export default () => {
  const [product, setProduct] = useState<any>();
  const image = product?.image;
  const shareImage = product?.share_image;
  const headImages = product?.head_images || [];
  const bodyImages = product?.body_images || [];

  // Actions
  const moveImage = async ({ collection, newIndex, oldIndex }: SortEndProps) => {
    if (newIndex === oldIndex) {
      return;
    }

    switch (collection) {
      case 'head_images': {
        setProduct({
          ...product,
          head_images: arrayMoveImmutable(headImages, oldIndex, newIndex),
        });
        await api.products.moveHeadImage(product.id, {
          old_index: oldIndex + 1,
          new_index: newIndex + 1,
        });
        break;
      }
      case 'body_images':
        setProduct({
          ...product,
          body_images: arrayMoveImmutable(bodyImages, oldIndex, newIndex),
        });
        await api.products.moveBodyImage(product.id, {
          old_index: oldIndex + 1,
          new_index: newIndex + 1,
        });
        break;

      default:
        break;
    }
  };

  // 重新上传多张图片
  const uploadImages = async (productId: string, imageListName: string, images: any[]) => {
    const params = { [`product[${imageListName}]`]: images };
    const existingFormData = objectToFormdata(params, { indices: false });
    await api.products.update(productId, existingFormData);
  };

  // 添加一张或多张图片
  const addImages = async (productId: string, imageListName: string, images: any[]) => {
    const params = { image_list_name: imageListName, images };
    const existingFormData = objectToFormdata(params, { indices: false });
    await api.products.addImages(productId, existingFormData);
  };

  return {
    gallery: {
      image,
      shareImage,
      headImages,
      bodyImages,

      // Actions
      moveImage,
      uploadImages,
      addImages,
    },
    setProduct,
  };
};
