import { useState } from 'react';
import api from '@/service/api';
import { message } from 'antd';
import { objectToFormdata } from '@/utils';

type Data = { [key: string]: any };

function Variant() {
  const [serviceModal, setServiceModal] = useState<any>({ open: false });
  const [currentVariant, setCurrentVariant] = useState<any>(); // 当前选中的variant
  const [loading, setLoading] = useState<boolean>(false); // 当前选中的variant

  const fetchVariant = async (variantId: string | undefined) => {
    if (!variantId) return;

    setLoading(true);
    try {
      const result: Data = await api.variants.detail(variantId);
      if (!!result?.variant) {
        setCurrentVariant(result.variant);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // Service Modal
  const openServiceModal = (data?: any) => {
    setServiceModal({ open: true, data });
  };
  const closeServiceModal = () => {
    setServiceModal({ open: false });
  };

  // Service Actions
  const success = () => {
    setServiceModal({ open: false });
    fetchVariant(currentVariant.id);
    message.success('保存成功');
  };

  const imageReducer = (values: Record<string, any>) => {
    const { image } = values;
    if (image?.[0]?.originFileObj) {
      values.image = image?.[0]?.originFileObj;
    } else {
      delete values.image;
    }

    return objectToFormdata(values, {}, new FormData(), 'sale_service');
  };

  const createService = async (values: any) => {
    const imageValues = imageReducer(values);

    try {
      await api.services.create(imageValues);
      success();
    } catch (error: any) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const updateService = async (id: string, values: any) => {
    const imageValues = imageReducer(values);

    try {
      await api.services.update(id, imageValues);
      success();
    } catch (error: any) {
      if (error.message) {
        message.error(error.message);
      }
    }
  };

  const toggleService = async (id: string) => {
    try {
      await api.services.toggle(id);
      success();
    } catch (error: any) {
      console.log(error);
    }
  };

  return {
    loading,
    fetchVariant,
    setCurrentVariant,
    currentVariant,

    serviceModal,
    openServiceModal,
    closeServiceModal,

    createService,
    updateService,
    toggleService,
  };
}

export default Variant;
