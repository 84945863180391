// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","parentId":"@@/global-layout","id":"1"},"2":{"path":"/","redirect":"/dashboard","parentId":"1","id":"2"},"3":{"path":"/dashboard","parentId":"1","id":"3"},"4":{"path":"/orders","parentId":"1","id":"4"},"5":{"path":"/orders","redirect":"/orders/list","parentId":"4","id":"5"},"6":{"path":"/orders/list","parentId":"4","id":"6"},"7":{"path":"/orders/list/:id","parentId":"4","id":"7"},"8":{"path":"/orders/analysis","parentId":"4","id":"8"},"9":{"path":"/orders/refund_managements","parentId":"4","id":"9"},"10":{"path":"/customers","parentId":"1","id":"10"},"11":{"path":"/customers/:id","parentId":"1","id":"11"},"12":{"path":"/miniapps","parentId":"1","id":"12"},"13":{"path":"/miniapps","redirect":"/miniapps/products","parentId":"12","id":"13"},"14":{"path":"/miniapps/products","parentId":"12","id":"14"},"15":{"path":"/miniapps/products/:productId","parentId":"12","id":"15"},"16":{"path":"/miniapps/collections","parentId":"12","id":"16"},"17":{"path":"/miniapps/coupons","parentId":"12","id":"17"},"18":{"path":"/miniapps/gift_settings","parentId":"12","id":"18"},"19":{"path":"/miniapps/articles","parentId":"12","id":"19"},"20":{"path":"/miniapps/banners","parentId":"12","id":"20"},"21":{"path":"/miniapps/banners/new","parentId":"12","id":"21"},"22":{"path":"/miniapps/banners/:id/edit","parentId":"12","id":"22"},"23":{"path":"/miniapps/qrcodes","parentId":"12","id":"23"},"24":{"path":"/others","parentId":"1","id":"24"},"25":{"path":"/others","redirect":"/others/channels_sharer","parentId":"24","id":"25"},"26":{"path":"/others/channels_sharer","parentId":"24","id":"26"},"27":{"path":"/users","parentId":"1","id":"27"},"28":{"path":"/users/edit_password","parentId":"1","id":"28"},"29":{"path":"/users/sign_in","parentId":"@@/global-layout","id":"29"},"30":{"path":"/*","parentId":"@@/global-layout","id":"30"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "layouts__BasicLayout" */'@/layouts/BasicLayout.tsx')),
'2': React.lazy(() => import('./EmptyRoute')),
'3': React.lazy(() => import(/* webpackChunkName: "p__dashboard__index" */'@/pages/dashboard/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "components__RouteContainer__index" */'@/components/RouteContainer/index.tsx')),
'5': React.lazy(() => import('./EmptyRoute')),
'6': React.lazy(() => import(/* webpackChunkName: "p__orders__list__index" */'@/pages/orders/list/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__orders__list__detail__index" */'@/pages/orders/list/detail/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__orders__analysis__index" */'@/pages/orders/analysis/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__orders__refund_managements__index" */'@/pages/orders/refund_managements/index.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__customers__index" */'@/pages/customers/index.tsx')),
'11': React.lazy(() => import(/* webpackChunkName: "p__customers__detail__index" */'@/pages/customers/detail/index.tsx')),
'12': React.lazy(() => import(/* webpackChunkName: "components__RouteContainer__index" */'@/components/RouteContainer/index.tsx')),
'13': React.lazy(() => import('./EmptyRoute')),
'14': React.lazy(() => import(/* webpackChunkName: "p__miniapps__products__index" */'@/pages/miniapps/products/index.tsx')),
'15': React.lazy(() => import(/* webpackChunkName: "p__miniapps__products__detail__index" */'@/pages/miniapps/products/detail/index.tsx')),
'16': React.lazy(() => import(/* webpackChunkName: "p__miniapps__collections__index" */'@/pages/miniapps/collections/index.tsx')),
'17': React.lazy(() => import(/* webpackChunkName: "p__miniapps__coupons__index" */'@/pages/miniapps/coupons/index.tsx')),
'18': React.lazy(() => import(/* webpackChunkName: "p__miniapps__gift_settings__index" */'@/pages/miniapps/gift_settings/index.tsx')),
'19': React.lazy(() => import(/* webpackChunkName: "p__miniapps__articles__index" */'@/pages/miniapps/articles/index.tsx')),
'20': React.lazy(() => import(/* webpackChunkName: "p__miniapps__banners__index" */'@/pages/miniapps/banners/index.tsx')),
'21': React.lazy(() => import(/* webpackChunkName: "p__miniapps__banners__update__index" */'@/pages/miniapps/banners/update/index.tsx')),
'22': React.lazy(() => import(/* webpackChunkName: "p__miniapps__banners__update__index" */'@/pages/miniapps/banners/update/index.tsx')),
'23': React.lazy(() => import(/* webpackChunkName: "p__miniapps__qrcodes__index" */'@/pages/miniapps/qrcodes/index.tsx')),
'24': React.lazy(() => import(/* webpackChunkName: "components__RouteContainer__index" */'@/components/RouteContainer/index.tsx')),
'25': React.lazy(() => import('./EmptyRoute')),
'26': React.lazy(() => import(/* webpackChunkName: "p__others__channels_sharer__index" */'@/pages/others/channels_sharer/index.tsx')),
'27': React.lazy(() => import(/* webpackChunkName: "p__users__index" */'@/pages/users/index.tsx')),
'28': React.lazy(() => import(/* webpackChunkName: "p__users__update__EditPassword" */'@/pages/users/update/EditPassword.tsx')),
'29': React.lazy(() => import(/* webpackChunkName: "p__login__index" */'@/pages/login/index.tsx')),
'30': React.lazy(() => import(/* webpackChunkName: "p__404__index" */'@/pages/404/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/var/www/tribe/shared/frontend_build/src/layouts/index.tsx')),
},
  };
}
