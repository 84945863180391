// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/var/www/tribe/shared/frontend_build/src/models/developer';
import model_2 from '/var/www/tribe/shared/frontend_build/src/models/login';
import model_3 from '/var/www/tribe/shared/frontend_build/src/models/media';
import model_4 from '/var/www/tribe/shared/frontend_build/src/pages/miniapps/products/models/image';
import model_5 from '/var/www/tribe/shared/frontend_build/src/pages/miniapps/products/models/product';
import model_6 from '/var/www/tribe/shared/frontend_build/src/pages/miniapps/products/models/variant';
import model_7 from '/var/www/tribe/shared/frontend_build/src/pages/miniapps/collections/model';

export const models = {
model_1: { namespace: 'developer', model: model_1 },
model_2: { namespace: 'login', model: model_2 },
model_3: { namespace: 'media', model: model_3 },
model_4: { namespace: 'miniapps.products.image', model: model_4 },
model_5: { namespace: 'miniapps.products.product', model: model_5 },
model_6: { namespace: 'miniapps.products.variant', model: model_6 },
model_7: { namespace: 'miniapps.collections.model', model: model_7 },
} as const
