import React from 'react';
import { history } from 'umi';
import { Button, Result, GetProps } from 'antd';

const pool = {
  '403': {
    status: '403',
    title: '403',
    subTitle: '抱歉，您无权访问此页面',
  },
  '404': {
    status: '404',
    title: '404',
    subTitle: '抱歉，您访问的页面不存在',
  },
  '500': {
    status: '500',
    title: '500',
    subTitle: '抱歉，出了一些问题',
  },
};

const Exception: React.FC<{ type: keyof typeof pool }> = ({ type }) => {
  return (
    <Result
      {...(pool[type] as Pick<GetProps<typeof Result>, 'status' | 'title' | 'subTitle'>)}
      extra={
        <Button type="primary" onClick={() => history.replace('/')}>
          返回首页
        </Button>
      }
    />
  );
};

export default Exception;
