import { useState, useEffect } from 'react';

type Output = [string, (tab: string) => void];

const useActiveTab = (defaultTab: string = '', tabFieldName: string = 'tab'): Output => {
  const url = new URL(window.location.href);
  const tabQuery = url.searchParams.get(tabFieldName);
  const [activeTab, setActiveTab] = useState(tabQuery || defaultTab);

  const setTab = (tab: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(tabFieldName, tab);
    window.history.pushState({}, '', url);
    setActiveTab(tab);
  };

  // 监听 popstate 事件，确保在前进或后退时更新状态
  useEffect(() => {
    const handlePopState = () => {
      const url = new URL(window.location.href);
      const tabQuery = url.searchParams.get(tabFieldName);
      if (tabQuery) {
        setActiveTab(tabQuery);
      } else {
        setActiveTab(defaultTab);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [defaultTab, tabFieldName]);

  return [activeTab, setTab];
};

export default useActiveTab;
