import React, { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import colors from '@/theme/color.json';
/**
 * 可以定制token
 */
const Provider: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: { colorPrimary: colors.primary },
        components: {
          Menu: {
            itemActiveBg: '#ddd',
            itemSelectedBg: '#eee',
            horizontalLineHeight: '60px',
            activeBarBorderWidth: 2,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default Provider;
