import { useState } from 'react';
import api from '@/service/api';

function Image() {
  const [currentImage, setCurrentImage] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const setImage = async (imageId: string | undefined) => {
    if (!imageId) return;

    setLoading(true);
    const res: Record<any, any> = await api.images.detail(imageId);
    setLoading(false);

    if (res?.image) {
      setCurrentImage(res.image);
    }
  };

  return {
    loading,
    setImage,
    setCurrentImage,
    currentImage,
  };
}

export default Image;
